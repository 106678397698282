.PolicyNumbersDiv {
  /* display: flex;
    flex-wrap: wrap;
    justify-content: space-around; */
  padding-left: 10px;
  padding-right: 10px;
  /* z-index: 9; */
}
a{
  text-decoration: none;
}

.PolicyNumbersDetails {
  margin: 16px 0;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  height: 124px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background-color: red; */

}
.selectedPolicy{
  border: 1px solid #e42125!important;
}
.PolicyNumbersDetails_text {
  margin-left: 20px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #242424;
}
.PolicyNumbersDetails_text > .policy_name {
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: #7c7c7c;
}
.PolicyNumbersDetails_text {
  font-weight: 600;
  font-size: 14px;
}
.PolicyNumbersDetails_text_p {
  margin-top: 1px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #242424;
}
.PolicyNumbersDetails_text_p_l {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: rgb(46, 45, 45);
}
.PolicyNumbersDetails_text_p_l span.label {
  display: inline-block;
  font-weight: normal;
  color: rgb(126, 124, 124);
}
.PolicyNumbersDetails_text_p_l span.value {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #e42125;
  margin-top: 2px;
}
.PolicyNumbersDetails_text_p_l_expiryDate{
  margin-right: -62px !important;
  text-align: right;
}

.PolicyNumbersDetails_div {
  width: 62px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% 50%;
  background: #fce9e9;
  margin-top: -44px;
}
.PolicyNumbersDetails_image {
  /* width:30px; */
  /* height: 25pxpx; */
  width: 40%;
}
.policyStatus_doc_ver_div {
  /* width: fit-content; */
  width: 100px;
  height: 26px;
  color: #3A3A3A;
  margin-top: -60px;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1px 4px;
  font-size: 12px;
  line-height: 18px;
}
.bgGreen {
  background: #e6f8e6;
}
.bgGreenDot{
  background: #4DC84D;
}
.bgOrange {
  background: rgba(253, 116, 12, 0.1);
}
.bgOrangeDot{
  background: rgba(253, 116, 12, 0.7)
}

.Loader_In_Policy {
   margin-top: 10%; 
}

.PolicyNumbersDetails_Arrow {
  width: 49px;
  height: 30px;
  background: #fce9e9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -60px
}
.PolicyNumbersDetails_Arrow > img {
  width: 22px;
}
.policyStatus_doc_ver_dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 3px;
}
.policyStatusNotFound{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30%;
  font-weight: 600;
  font-size: 20px;
}
.Logout_policy{
  cursor: pointer;
  background-color: #e42125;
  color: white;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 400;
}
.policy_details_header{
  position: relative;
}
.policy_logout{
position: absolute;
right: 0;
margin-right: .9rem;
display: flex;
align-items: center;
justify-content: center;
height: 33px;
width: 33px;
border-radius: 50px;
background-color:#f7eaea!important;
border-radius: 50prgb(216, 148, 148);
cursor: pointer;
margin-top: -16px!important;
}
.policy_logout >img {
  height: 18px;
 width: 18px;
}

@media only screen and (min-width: 1025px){
  .PolicyNumbersDiv{
    /* display: flex; */
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 80vh;
    /* width: 300px; */
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 9;

  }
  .PolicyNumbersDiv::-webkit-scrollbar-thumb {
    width: 10px!important;
    background-color: rgb(172, 167, 167); /* or add it to the track */
    cursor: pointer;
  }

  .PolicyNumbersDetails{
    width: 360px;
    margin-bottom: 15px;
    margin-top: 10px;
    cursor: pointer;
  }
  .policyStatusNotFound{
    margin-top: 15%;
  }
  .policyWeb{
    display: flex;
    justify-content: space-between;
    z-index: 999;
  }
  .policyClaimWebView{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
    border-radius: 8px;
    box-shadow: 0px 10px 40px rgb(0 0 0 / 10%);
    height: 78vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  /* .policyStatusNotFound{
    position: fixed;
    left: 10%;
  } */
  .policyStatusNotFound{
    display: none;
  }
  ::-webkit-scrollbar {
    width: 2px;

  }
 
}

