* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
.otp-main {
  margin: 0 auto;
  width: 90%;
  margin-top: 2rem;
}
.no{
  display: flex;
  gap: 0.6rem;
  align-items: center;
  color: #0F0A39;
  opacity: 1;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.no>img{
  cursor: pointer;
}
.otp-sub {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.otp-text {
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  color: #1c1c1c;
  font-family: 'Poppins', sans-serif;
}
.otp-icon {
  height: 10rem;
  width: 7rem;
  margin: auto;
  display: block;
}

.otp-box {
  /* margin-left: 1rem; */
  /* display: flex; */
  /* justify-content: space-between; */
  align-items: center;
  
}

.otp-box div {
  justify-content: space-between !important;
}
.resend-otpp{
  display: flex;
    align-items: baseline;
    gap: 10px;
    justify-content: center;
}
.otp-button {
  /* position: absolute;
  bottom: 0; */
  margin: auto;
  display: block;
  margin-top: 1.4rem;
  font-family: 'Poppins', sans-serif;
  border: none;
  background-color: #e41e26;
  width: 100%;
  height: 55px;
  padding: 1rem;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.otp-container {
  margin-top: 18%;
  display: flex;
  justify-content: center;
  column-gap: 12px;
}
.otp-number {
  font-family: 'Poppins', sans-serif;
  height: 48px;
  width: 58px;
  border: none;
  background-color: #f7fff2;
  box-shadow: 0 0 24px -11px #888;
}
#partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  min-width: 220px;
}

.resent {
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  color: red;
  font-size: 16px;
  font-weight: 500;
  /* text-decoration: none !important; */
  text-decoration: underline;
  cursor: pointer;
}

.otp-count {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

@media only screen and (min-width: 1025px){
  .otp-main {
      /* background-color: red; */
      width: 400px!important;
      border: 1px solid #E8E8E8;
      border-radius: 5px;
      margin-top: 10%!important;
      padding-left:20px!important;
      padding-right:20px!important;
      padding-bottom: 20px!important;
  }
  .no{
    margin-bottom: 0;
  }
  .otp-text{
    line-height: 22px;
  }
}

