body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  min-width: 350px !important;
  padding: 10px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: auto 9px!important;
  color: red;
  margin-left: 9px!important;
  margin-right: 9px!important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
margin: 0px 0!important;
margin-left: 9px!important;
margin-right: 9px!important;
} */
/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0px 0 !important;
} */
/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
} */

@keyframes slide-back {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-0%);
  }
}
/* .main_cx_container {
  animation: slide-back 0.5s;
  height: 100%;
  overflow: hidden;
}  */


.server-down img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 12rem;
  display: block;
  height: 10rem;
}

/* Alert status */
.status_backlog {
  background-color: black;
  opacity: .7;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
}

.person-detail {
  /* display: flex; */
  user-select: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width:600px) {
  .main_app_container {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 100vh;
  } 
  .main_app_subcontainer {
    width: 500px;
    max-width: 600px;
    border: 1px solid rgb(228, 224, 224);
    padding: 10px;
  }
 
}
@media (min-width: 1025px){


  .main_app_subcontainer {
    width: 100%;
    max-width: 100%;
     border: none
   /* padding: 10px; */
  }
  
  

}
