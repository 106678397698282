.ratings {
  height: 180px;
  width: 100%;
  box-shadow: 0 0 24px -11px #888;
  border-radius: 8px;
  padding: 10px 20px;
  margin-top: 10px;
  position: relative;
  animation: mobeToTop 4s;
}
@keyframes mobeToTop {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  25% {
    opacity: 0.25;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.ratings1 {
  height: 180px;
  width: 100%;
  box-shadow: 0 0 24px -11px #888;
  border-radius: 8px;
  padding: 10px 20px;
  margin-top: 10px;
  position: relative;
}

.taptoRatingDiv {
  width: 100%;
  /* box-shadow: 0 0 24px -11px #888; */
  border-radius: 8px;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #e42125;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ratingsHeaders {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.ratingsText {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  opacity: 0.8;
  line-height: 16px;
  margin-top: 10px;
  text-align: center;
}
.ratingsTextCnf {
  font-weight: 300;
  font-size: 14px;
  opacity: 0.8;
  margin-top: 1px;
  margin-bottom: 10px;
}
.ratingsBoxDiv {
  display: flex;
  justify-content: space-around;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.ratingsBox {
  width: 38px !important;
  height: 40px;
  /* background-color: #e42125; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex: 1 0 0; */
  border: 1px solid rgb(0 0 0 / 5%);
  border-left: none;
}
.ratingsBox:first-child {
  border-left: 1px solid rgb(0 0 0 / 5%);
}
.ratingsPopup {
  background-color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 153532;
}
.ratingsPopupDiv {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  text-align: center;
  width: 320px;
  height: 150px;
  padding: 40px 20px;
  box-shadow: 0 0 24px -11px #888;
  border-radius: 8px;
  background-color: white;
}
.showRatingPopup {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ratingsButtonDiv {
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
}
.rattingButton {
  width: 80px;
  height: 30px;
  border: none;
  outline: none;
  color: #ffffff;
  border-radius: 5px;
  background-color: #4dc84d;
  cursor: pointer;
}
.rattingButtonCancle {
  background-color: #e94d51;
}
.selectedColor {
  background-color: #e42125;
  color: white;
  border: 1px solid #e42125;
}
.Rating_progress {
  display: flex;
  width: 240px;
  height: 7px;
  border-radius: 16px;
  /* background: red; */
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7px;
}
.Rating_progress_r {
  display: block;
  background: red;
  width: 30%;
  height: 7px;
  /* border-radius: 2px; */
}
.Rating_progress_y {
  display: block;
  background: #ffc107;
  width: 40%;
  height: 7px;
  /* border-radius: 2px; */
}
.Rating_progress_g {
  display: block;
  background: #4caf50;
  width: 30%;
  height: 7px;
  /* border-radius: 2px; */
}
.ratingClose {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #e42125;
  position: absolute;
  top: 5px;
  right: 5px;
}
.ratingClose > img {
  width: 90%;
}
.ratings1Text {
  text-align: center;
  margin-top: 50px !important;
}
.Rating_progress_text {
  display: flex;
  justify-content: space-evenly;
}
.Rating_progress_text > p {
  font-size: 12px;
  font-weight: 300;
  opacity: 0.8;
}
.ratingSubmitBtn {
  /* margin-top: 10px!important; */
  width: 110px;
  height: 24px;
  border: none;
  outline: none;
  background-color: #e42125;
  color: white;
  border-radius: 8px;
  position: absolute;
  right: 5px;
  bottom: 3px;
  cursor: pointer;
}
.thanksforsubmittingdiv {
  /* height: 280px; */
  width: 100%;
  box-shadow: 0 0 24px -11px #888;
  border-radius: 8px;
  padding: 16px 20px;
  /* margin-top: 10px; */
  text-align: center;
  font-size: 14px;
  position: relative;
}
.thanksforsubmittingimg {
  width: 40px;
  background-color: #4caf50;
  border-radius: 50px;
}

@media only screen and (min-width: 1025px) {
  .ratings {
    width: 353px;
    position: FIXED;
    z-index: 99999999;
    bottom: 5px;
    background: #fff;
    right: 16px;
  }
  .ratings1 {
    width: 353px;
    height: 153px;
    position: FIXED;
    z-index: 99999999;
    bottom: 5px;
    background: #fff;
    right: 16px;
  }
  .taptoRatingDiv {
    width: 143px;
    height: 23px;
    position: FIXED;
    bottom: 5px;
    right: 16px;
    font-size: 12px;
  }

  .ratings1Text {
    text-align: center;
    margin-top: 25px;
  }
  .thanksforsubmittingdiv {
    width: 353px;
    /* height: 100px; */
    position: FIXED;
    z-index: 99999999;
    bottom: 5px;
    background: #fff;
    right: 16px;
    box-shadow: 0 0 24px -11px #888;
    border-radius: 8px;
    padding: 10px 20px;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
  }
}

/* @media only screen and (max-width: 625px){
    .ratings{
       position: fixed;
       bottom: 0;
       z-index: 1111111111;
       background-color: #888;
    }
  } */
