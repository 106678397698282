* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.stories-main {
  margin-top: 10px;
}
.stories-text {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}
.story-section {
  width: 110%;
  
}
.story-img {
  height: 76px;
}
.story-img:hover {
  transform: rotate(-5deg);
}
.story-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.story-button .play_btn {
  position: absolute;
    top: 27%;
    left: 35%;
    bottom: 0;
}
.story-ul {
  margin-bottom: 2px;
}

.sto {
  display: flex;
  justify-content: space-around;
}
/* ================ */

li {
  list-style: none;
}
.rotate:hover {
  transform: rotate(-5deg);
}
.outer {
  background: linear-gradient(to right, #ed1c24, #fcee21);
  border: 5px solid #fff;
  border-radius: 100%;
  padding: 3px;
  height: 77px;
  position: relative;
  box-shadow: 0px 1px 10px #00000012 !important;

}

@keyframes slide-back {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-0%);
  }
}
@keyframes slide-front {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
.display {
  background-color: #ffff;
  position: fixed;
  width: 100%;
  height: 150%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  transition: 10s linear all;
  animation: slide-back 0.5s;
  overflow: hidden;
}

.hidden {
  display: none;
  transition: 10s linear all;
  animation: slide-front 0.5s;
  /* overflow: hidden; */
}
.full {
  width: 100%;
  height: 100%;
  /* width: 400px; height:300px position:absolute; z-index:999; top: 50%; left:50%; margin-top: -150px; margin-left: -200px; overflow: auto; */
}
.overlay{
  width: 100vw;
 }

/* For Skeleton Loading */
.skeleton-sto {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.video_close {
  position: fixed;
  top: 37rem;
  right: 10rem;
  font-weight: 700;
  font-size: 2rem;
  opacity: 0.6;
}
.close-story {
  height: 3rem;
  float: left;
  margin-top: -3rem;
  margin-right: -1rem;
}
.close-icon {
  position: absolute;
  left: 7rem;
  bottom: 34rem;
  cursor: pointer;
}


@media only screen and (min-width:600px) {
 .drawer-container{
  width: 100%;
 }

 .close-icon{
  position: absolute;
  
 }
 
 .MuiDrawer-paperAnchorLeft{
  width: 100vw;
  position: relative;
 }
}
@media only screen and (min-width: 1025px){
  .stories-main{
    width: 69vw;
    /* min-width: 40rem; */
    margin-top: 4px;
    min-width: 35rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px 10px;
  }
}



