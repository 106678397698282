.claims_list_main_container {
  animation: scaling 0.3s;
}
.claim-box {
  border: 1px solid #1eb513;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.6rem;
  margin-top: 1.5rem;
}
.bo {
  display: flex;
}

.claim-first {
  display: flex;
  flex-direction: column;
  padding: 0px 0.5rem 0px 1rem;
}
.claim-inner-first {
  display: flex;
  gap: 1rem;
}
.claim-box2 {
  width: 100%;
  /* border-radius: 4px; */
}
.claim-box2:hover {
  background-color: #f1f1f1;
}
.claim-second {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 1px 3px #7070700f; */
  padding: 0.6rem 0.6rem 0.6rem 0;
  border-bottom: 1px solid #f1f1f1;
}
.claim-text-container {
  background: rgba(65, 132, 243, 0.1);
  padding: 10px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
}
.claim-inner-second {
  display: flex;
  gap: 1rem;
  flex: 1;
}
.show-claim-text {
  font-weight: 500;
  color: #4c4c4c;
  opacity: 1;
}
.claim-id-text {
  color: #4c4c4c;
  opacity: 0.75;
  font-size: 15px;
}
.claim-id {
  color: #3f3f3f;
  opacity: 1;
  font-size: 15px;
}
.data_not_found {
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
}
.data_not_found div {
  width: 70%;
  margin: auto;
}
.data_not_found div p {
  text-align: center;
  font-size: 15px;
}
.data_not_found div img {
  margin-left: auto;
  margin-right: auto;
  width: 110px;
  height: 110px;
}
.claim_list_container {
  margin-top: 25px;
}
.serverDown_header {
  width: 100%;
}
.notfoundtextD {
  display: none;
}
.notfoundtextM {
  display: block;
}
@media only screen and (min-width: 1025px) {
  .claimLoader {
    width: 10%;
    position: fixed;
    left: 48rem;
  }
  .serverDownImg {
    display: none;
  }
  .serverDown_header {
    position: fixed;
    left: 15%;
  }
  .notfoundtextD {
    display: block;
  }
  .notfoundtextM {
    display: none;
  }
}
