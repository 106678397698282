/* body {
  margin: 0 auto;
  width: 90%;
} */
.claim-detail-main {
  /* box-shadow: 0 0 24px -11px #888; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 35px #00000012;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 8px;
}
.claim-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 6px;
}
.claim-details-sub {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.float-right {
  position: absolute;
  left: 94%;
}
.claim-details-text {
  font-size: 12px;
  /* margin-top: 0.4rem; */
  /* margin-top: 1rem; */
}
/* For claim-detail-inner */
.claim-detail-inner {
  /* display: flex; */
  column-gap: 12px;
  line-height: 30px;
  margin-left: 10px;
  margin-right: 6px;
  margin-top: 3px;
  max-height: 1000px;
  transition: max-height 0.5s;
  background-color: white;
  padding: 0 0 2rem 0;
  transition: all 0.5s ease-in-out;
  /* overflow: scroll; */
  /* border-top: 1px solid lightgrey; */
}

.hide {
  /* display: flex; */
  column-gap: 12px;
  line-height: 30px;
  margin-right: 6px;
  margin-left: 10px;
  margin-top: 3px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s;
  transition: all 2.5s cubic-bezier(0, 1, 0, 1);
  transition: all 0.5s ease-in-out;
  background-color: white;
}

.claim-call {
    float: left;
    text-align: left;
    font-size: 13px;
    color: #252525;
    opacity: 0.50;
    min-width: 140px;
    flex: 1;
}
.claim-caller {
  float: right;
  color: black;
  font-size: 13px;
  font-weight: 600;
  min-width: 181px;
  line-height: 1.7;
  flex: 1;
}

@media only screen and (min-width: 1025px) {

  .claim-call {
    float: left;
    text-align: left;
    font-size: 12px;
    color: #252525;
    opacity: unset;
    /* min-width: 70px; */
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .claim-caller {
    float: right;
    color: black;
    font-size: 12px;
    font-weight: 600;
    /* width: 90%; */
    width: 15rem;
    /* min-width: 191px; */
    margin-left: -63px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  /* .bankdetailsdesktopview>div{
    /* border: 1px solid red; */
    /* width: 33%; */
    /* word-wrap: normal!important; */
  /*} */

}
