.claimStatus {
  width: 100%;
  padding: 3px;
  position: relative;
}
.claimStatus_top {
  display: flex;
  align-items: center;
}
.claimStatus_top > p {
  font-weight: 500;
  font-size: 16px;
}
.claimStatus_top > img {
  height: 11.5px;
  width: 15px;
  margin-left: 3px;
}
.claimStatus_section2 {
  /* height: 270px; */
  margin-bottom: 10px;
}
.claimStatus_section2 > p {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}
.claimStatus_section3 {
  min-width: 300px;
}
.claimStatus_tab {
  width: 100%;
  /* height: 50px; */
  /* border: 1px solid #e8e8e8; */
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); */
  /* border-radius: 8px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .claimStatus_tab > div {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a7a7a7;
  font-weight: 400;
  font-size: 14px;
  border: none;
  outline: none;
  margin: 5px;
  background-color: white;
} */
.activeTabClass {
  color: #ffffff;
  border-bottom: 2px solid #e42125;
  color: #e42125;
  animation-name: activeTabClassAnimation;
  animation-duration: 3s;
}
@keyframes activeTabClassAnimation {
  0% {
    border-bottom: 2px solid white;
  }
  100% {
    border-bottom: 2px solid #e42125;
  }
}
.statusText {
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.top_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}
.top_header_container .top_header_container_1 {
  flex: 2;
}
.log_out_btn {
  background-color: #e42125;
  padding: 5px;
  border-radius: 15px;
  color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(247, 234, 234) !important;
  cursor: pointer;
}

.log_out_btn img {
  width: 18px;
  height: 18px;
  color: #e41e26;
}
/*========================================CashlessPopUp==========================================================*/

.CashlessPopUp{
  background-color: #e41e26;
}
.hrlineClaimStatus{
  width: 25px;
  transform: rotate(90deg);
  margin-left: -5px;
  margin-right: -5px;
  border: 1px solid #d8d8d8;
}


/* claimStatus_tab */

@media only screen and (min-width: 1025px){
  .claimStatus_tab{
    width: 560px;  
    margin-left: auto;
    margin-right: auto;  
  }
  .claim_section_container{
     display: flex;
    justify-content: space-between; 
    flex-wrap: wrap;
    margin-left: 15px;
    /* margin-right: 15px; */
    margin-top: 30px;
    /* margin-right: 30px; */
    /* width: 390px;  */
    width: 68.6vw;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
}
  
  .noClaimsFoundimg{
width:68.6vw;
  }
  
}