.document-upload-sub {
  display: flex;
  column-gap: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #252525;
  opacity: 1;
}
.additional-document {
  margin-top: 10px;
  margin-bottom: 10px;
}
.additional-document-text {
  font-size: 12px;
  font-weight: 600;
  color: #252525;
  opacity: 1;
}
.additional-document-bold {
  font-size: 14px;
  font-weight: 500;
  color: #252525;
  opacity: 1;
}

/* Input Type File */
/* input[type="file"] {
  border: 1px solid #333;
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  padding: 8px;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  width: 60%;
  border-radius: 5px;
  border: 1px dashed darkgrey;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  z-index: 1;
}
input[type="file"]:after {
  background-color: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

input[type="file"]::-webkit-file-upload-button {
  background: #fff;
  color: #555;
  font: inherit;
  padding: 0;
  position: relative;
  border: none;
  text-align: left;
  position: relative;
  z-index: 100;
  -webkit-appearance: none;
} */
.upload-button {
  padding: 10px;
  margin-left: 6px;
  width: 170px;
  height: 45px;
  border: none;
  color: #fff;
  background-color: #e41e26;
  border-color: #e41e26;
  border-radius: 4px;
  flex-grow: 0.1;
  /* display: none; */
}
.uploadCancBtn {
  margin: 10px 0;
  display: flex;
  justify-content: space-around;
}
.additional-doc-background {
  background-color: #e41e2640;
  opacity: 0.4;
}

/*  */

/* Test */
.file-up > img {
  margin-right: 5px;
  /* margin-left: 10px; */
}
.file-up {
  border-radius: 4px;
  color: #e41e26;
  flex: 0.9 1;
  /* max-width: 90%; */
  width: 100% !important;
  /* max-height: 50px; */
  font-size: 14px;
  /* padding: 3px 3px 3px 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.fileinputs {
  width: 100%;
}
.uploadBtn {
  width: 180px !important;
  height: 36px;
  background-color: #fff8f8;
  border: 1px solid #e41e26;
  color: #e41e26;
  font-size: 15px;
  margin: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DocumentUploadPartUploadCanc {
  background-color: rgb(247, 234, 234) !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e41e26;
}
/* .DocumentUploadPartUploadCanc >img{
width: 88%;
} */

.document_validation {
  font-size: 12px;
  text-align: center;
}
.document_upload_selected{
  background-color: #f4f4f4!important;
}

/* margin-top: 24px;
    padding: 16px;
    box-shadow: 0px 3px 35px #00000012 !important; */
    @media only screen and (min-width: 1025px) {
      .file-up {
        width: 330px !important;
        margin-left: auto;
        margin-right: auto;
      }
      
      
      

    }