* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
body {
  font-family: 'Poppins', sans-serif;
}

.card-main {
  position: relative;
  height: 9.5rem;
  padding: 8px;
  border-radius: 5px;
  background-image: linear-gradient(0deg, #18b10d, #77de6fdb);
}
.card-content {
  display: flex;
  justify-content: space-around;
  color: #f2f2f2;
  font-size: 12px;
}
.claim-status {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: #f2f2f2;
}
.claim-status-flex {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  font-size: 12px;
}
/* For Font Sizing */
.semi-bold {
  font-weight: 500;
}

.green-dot {
  height: 10px;
  width: 10px;
  background-color: #1eb513;
  border-radius: 50%;
  display: inline-block;
}

.box1 {
  margin-top: 10px;
}
.box2 {
  margin-top: 10px;
}
.box1:after {
  content: '';
  background-color: #ffffff;
  position: absolute;
  width: 1px;
  height: 40px;
  top: 12px;
  left: 45%;
  display: block;
}
.card-amount {
  text-align: center;
}

/* @media only screen and (min-width: 300px) {
  .claim-call {
    float: left;
    text-align: left;
    font-size: 5px;
    color: #252525;
    opacity: 0.5;
    min-width: 100px;
  }
  .claim-caller {
    float: right;
    color: black;
    font-size: 5px;
    font-weight: 600;
    min-width: 181px;
  }
} */

.card-main-skeleton {
  position: relative;
  height: 8.5rem;
  border-radius: 5px;
}
