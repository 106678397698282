.claim-status-tracker-text {
  margin-top: 22px;
  font-size: 16px;
  font-weight: 700;
  color: #1c1c1c;
  opacity: 0.84;
}
/*  */
.claim-status-main {
  padding: 15px;
  margin-bottom: 18px;
}
.claim-status-main_preauth {
  padding: 15px;
  margin-bottom: 18px;
}
.step {
  position: relative;
  min-height: 1em;
  /* color: gray; */
}
.title {
  color: #252525;
  opacity: 1;
  font-size: 13px;
  line-height: 1.5em;
  font-weight: 600;
}
.caption {
  font-size: 12px;
  letter-spacing: 0px;
  color: #252525;
  opacity: 0.5;
}
.step + .step {
  margin-top: 1.5em;
}
.step > div:first-child {
  position: static;
  height: 0;
}
.step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
} /* Circle */

.circle {
  background: #d8d3d3;
  position: relative;
  width: 1em;
  height: 1em;
  line-height: 1.5em;
  border-radius: 50px;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 3px #fff;
  z-index:9;
}
.step:last-child .circle:after {
  display: none;
}
.step.step-active {
  /* color: green; */
}
.step.step-active .circle {
  background-color: #d8d3d3;
}
.align-words {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

@media only screen and (min-width: 1025px) {
  .claim_status_tracker_container {
    /* box-shadow: 0 0 24px -11px #888; */
    width: 1050px;
    min-width: 1050px;
    max-width: 1050px;
    border-radius: 8px;
    /* margin: 20px auto; */
    padding: 10px 20px;
    overflow-x: scroll;
  }
  .claim_status_tracker_containerCashless{
    width: 95vw;
    min-width: 1295px;
    max-width: 1295px;

  }
  .step {
    flex: 1 0 0;

  }
  .claim-status-main {
    padding: 15px;
    margin-left: 114px;
    /* margin-bottom: 18px; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* margin-left: auto!important; */
    /* margin-right: auto!important; */
  }
  .claim-status-main_preauth {
    padding: 15px;
    margin-left: 114px;
    /* margin-bottom: 18px; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76%;
    margin-left: auto!important;
    /* margin-right: auto!important; */
   
  }

  .first_step:after {
    top: 6px !important;
    height: 1px !important;
    width: 15rem !important;
    /* margin-left: 8px;  */
  }
  .step:first-child {
    margin-top: 23px;
  }
  .verification_step:after {
    top: 6px !important;
    height: 1px !important;
    width: 14.5rem !important;
    margin-left: 8px;
  }
  .claim_status:after {
    top: 6px !important;
    height: 1px !important;
    width: 15rem !important;
    margin-left: 10px; 
  }
  .title {
    color: #252525;
    opacity: 1;
    font-size: 13px;
    line-height: 1.5em;
    font-weight: 600;
    margin-top: 20px;
    
  }
  .align-words {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    position: relative;
    left: -99px;
  }
  .claim-status-tracker-text {
    margin-top: 0;
    margin-left: 20px;
    display: none;
  }
}
