.card-initiate-main {
    height: 9.5rem;
    padding: 12px;
    border-radius: 5px;
    background-image: linear-gradient(0deg, #852d91, #312a6c);
  }
  .card-content {
    display: flex;
    justify-content: space-around;
    color: #f2f2f2;
    font-size: 11px;
  }
  .initiate-card-amount {
    font-size: 14px;
    color: #f2f2f2;
    opacity: 1;
    font-weight: 600;
    text-align: center;
  }
  .initiate-card-status {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: #f2f2f2;
    padding-bottom: 6px;
    gap: 8px;
  }
  
  /* For Font Sizing */
  .initiate-semi-bold {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 14px;
    color: #f2f2f2;
    opacity: 1;
  }
  .link {
    border-bottom: 1px solid;
    cursor: pointer;
  }
  .initiate-less-bold {
    font-size: 12px;
    color: #f2f2f2;
    font-weight: 100;
    opacity: 0.84;
    cursor: pointer;
  }
  
  .initiate-card-box {
    margin-top: 10px;
  }
  
  .card-amount {
    text-align: center;
  }
  .notificationColor{
    background-color: rgb(255, 210, 127)!important;
  }
  .actionButton{
    width: 98px;
    height: 24px;
    background-color: #852d91;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .actionRequiredCard{
    position: relative;
    border-radius: 8px;
    padding-top: 8px!important;
    padding-bottom: 1px!important;
  }
  .actionRequiredCross{
    position: absolute;
    top: 8px;
    right: 12px;
   cursor: pointer;
  }