.card-yellow-main {
  height: 9.5rem;
   padding: 6px;
   border-radius: 5px;
   /* background-image: linear-gradient(0deg, #ff9411, #ffb04f); */
   background: linear-gradient(180deg, #ffa500 0%, #ffdb99 100%);
   display: flex;
   justify-content: center;
}
.card-content {
  display: flex;
  justify-content: space-around;
  color: #f2f2f2;
  font-size: 11px;
}
.yellow-card-amount {
  font-size: 14px;
  color: #f2f2f2;
  opacity: 1;
  font-weight: 600;
  text-align: center;
}
.yellow-card-status {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: #f2f2f2;
  padding-bottom: 6px;
}

/* For Font Sizing */
.yellow-semi-bold {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  color: #f2f2f2;
  opacity: 1;
}
.link {
  border-bottom: 1px solid;
  cursor: pointer;
}
.yellow-less-bold {
  font-size: 12px;
  color: #f2f2f2;
  font-weight: 100;
  opacity: 0.84;
  cursor: pointer;
}

.yellow-card-box {
  margin-top: 10px;
}

.card-amount {
  text-align: center;
}
