.loginPage{
  width: '90%'; 
  margin: '0 auto'
}

.text-field {
  position: relative;
  margin-top: 3rem !important;
}
.login-text {
  margin-top: 2rem;
  font-size: 20pt;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  color: #0F0A39;
  font-family: 'Poppins', sans-serif;
}
.login-text-desc{
  margin-top: 0.4rem;
  color: #505050;
}

.claim-text-container {
  display: flex;
  align-items: center;
}

.claim-text-container .claim-text-status {
  padding-left: 10px;
  font-weight: 600;
}

.claim-text-container .show-claim-text {
  font-weight: 400;
}
/* input {
  display: inline-block;
  border: thin solid #fafafa;
  border: solid 0.5px #f8f8f8;
  width: 100%;
  color: #343434;
  background-color: #fafafa;
  padding: 14px 10px 14px 10px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  outline: none;
} */
.login-input{
  display: inline-block;
  border: thin solid #fafafa;
  border: solid 0.5px #f8f8f8;
  width: 100%;
  color: #343434;
  background-color: #fafafa;
  padding: 14px 10px 14px 10px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  outline: none;
}
.login-input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:#737373;
  font-size: 14px;
}
.login-input::-moz-placeholder { /* Firefox 19+ */
  color:#737373;
  font-size: 14px;
}
.login-input:-ms-input-placeholder { /* IE 10+ */
  color:#737373;
  font-size: 14px;
}
.login-input:-moz-placeholder { /* Firefox 18- */
  color:#737373;
  font-size: 14px;
}



.login-input:focus {
  /* border: thin solid #e41e26; */
  border: solid 1px #e41e26;
  background-color: #fafafa;
}

label {
  color: #999;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s;
}

input:focus ~ label,
input:valid ~ label {
  top: -10px;
  left: 15px;
  font-size: small;
  color: #e41e26;
  border: solid 1px #e41e26;
  border-radius: 5px;
  background-color: #fff;
  padding: 0 5px 0 5px;
}

  .submit-button {
    /* position: absolute; */
    bottom: 0;
    font-family: 'Poppins', sans-serif;
    border: none;
    background-color: #e41e26;
    /* margin-top: 4rem; */
    width: 100%;
    height: 55px;
    padding: 1rem;
    margin-top: 1rem;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    /* position: fixed; */
    /* left: 50%; */
    /* top: 46%; */
    /* transform: translate(-50%, -50%); */
}
.termsAndCondition{
  position: fixed;
  bottom: 0;
  /* word-break: break-all; */
  font-size: x-small;
  font-weight: 400;
  width: 32%;
  min-width: 320px;
  margin-bottom: 20px;
}
.termsAndConditionF{
  margin-top: 120px;
  font-size: x-small;
  font-weight: 400;
  min-width: 320px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1025px){
  .loginPage {
      /* background-color: red; */
      width: 400px!important;
      border: 1px solid #E8E8E8;
      border-radius: 5px;
      margin-top: 10%!important;
      padding-left:20px!important;
      padding-right:20px!important;
      padding-bottom: 20px!important;
  }
  .main_app_subcontainer {
    width: 100%;
    max-width: 100%;
     border: none
   /* padding: 10px; */
  }
  .login-text{
 margin-top: 1rem;
  }
  .text-field {
    margin-top: 1rem !important;
  }
  .termsAndCondition{
    position: absolute;
    width: 96vw;
    /* min-width: 320px; */
    left: 40px;
  }
  .termsAndConditionF{
    position: absolute;
    bottom: 0;
    width: 96vw;
    left: 40px;
  }

}

