.claimStatus_section4 {
    width: 100%;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 20px;
    padding-bottom:10px;
    min-width: 320px;
    cursor:pointer;
  }
  .claimStatus_details {
    display: flex;
    justify-content: space-between;
  }
  .claimStatus_details_claims{
    padding-left: 8px;
    padding-top: 5px;
  }
  .claimStatus_details_claims > p:first-child {
    font-weight: 400;
    font-size: 10px;
    color: #7c7c7c;
  }
  .claimStatus_details_claims > p:last-child {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.8px;
    color: #242424;
    min-width: fit-content;
  }
  .claimStatus_details_claimsId > p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #7c7c7c;
    text-align: center;
    margin-top: 8px;
  }
  
  .claimStatus_reimbursement {
    width: fit-content;
    background: #f4f4f4;
    border-radius: 0px 4px 4px 0px;
    margin-top: 5px;
    padding: 4px 18px;

  }
  .claimStatus_reimbursement > p {
    font-weight: 400;
    font-size: 10px;
    color: #7c7c7c;
  }
  
  .claimStatus_doc_ver_div {
    width: 190px;
    height: 28px;
    background: #e42125;
    border-radius: 0px 8px 0px 12px;
    color: #ffffff;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    
  }
  .claimStatus_doc_ver_div > p {
    font-size: 12px;
  }
  
  .claimStatus_more {
    font-weight: 400;
    font-size: 10px;
    text-align: left;
    margin-top: 18px;
    padding-bottom: 2px;
  }

  .claimStatus_more p {
    padding: 0px 10px;
  }
  .claimStatus_more_span {
      color: #0000FF;
      cursor: pointer;
  }
  .required_documents_text{
    /* background-color: rgb(247, 122, 122); */
    width: fit-content;
    color: #e42125;
  }
  @media only screen and (min-width: 1025px){
  .claimStatus_section4{
    width: 390px;
  }
  
  }