.claim-amount-main {
  /* box-shadow: 0 0 24px -11px #888; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 35px #00000012;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 8px;
}
.claim-amount-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.claim-amount-rs {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: #252525;
  opacity: 0.76;
}
.claim-amount-text {
  font-size: 12px;
  color: #252525;
  opacity: 0.76;
}
.claim-amount-percent {
  text-align: center;
}
.claim-amount-per {
  font-size: 14px;
  font-weight: 600;
  color: #252525;
  opacity: 1;
}
.claim-amount-less {
  font-size: 12px;
  color: #252525;
  opacity: 0.72;
}
.reason_for_rejection {
  float: left;
  text-align: left;
  font-size: 15px;
  padding: 5px;
  line-height: 1.7;
  padding-bottom: 12px;
}
.claim-amount-inner {
  padding: 0 0 2rem 0;
  column-gap: 12px;
  line-height: 30px;
  margin-right: 6px;
  margin-left: 10px;
  margin-top: 3px;
  max-height: 1000px;
  transition: max-height 0.5s;
  background-color: white;
}
.claim-amount-hide {
  column-gap: 12px;
  line-height: 30px;
  margin-right: 6px;
  margin-left: 10px;
  max-height: 0px;
  overflow: hidden;
  margin-top: 3px;
  transition: max-height 0.5s;
  transition: all 2.5s cubic-bezier(0, 1, 0, 1);
  background-color: white;
}
.claim-amount-box1 {
  position: relative;
}
.claim-amount-box1::after {
  content: '';
  border-right: 1px solid #707070;
  position: absolute;
  width: 1px;
  height: 52px;
  top: 0px;
  left: 118%;
  display: block;
}
hr {
  width: 98%;
  margin: 0 auto;
  opacity: 0.60;
}

.claim_rejection_reason_cont .claim-amount-per {
  text-align: left;
  font-size: 15px;
}

/* Material Ui Override */
.MuiAccordion-root {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 35px #00000012 !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  margin-bottom: 8px !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  position: inherit !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0px 0 !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0px 6px 0px 6px !important;
}
.css-15v22id-MuiAccordionDetails-root {
  padding: 14px 10px 0px 10px !important;
    line-height: 2rem !important;
}
.css-o4b71y-MuiAccordionSummary-content {
  margin: 8px 0 !important;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  padding: 1rem !important;
}
.MuiAccordion-region {
  /* box-shadow: 0px 5px 6px #00000012 !important; */
  margin-bottom: 8px !important;
}
/* Newly Added */
.css-u7qq7e {
  padding: 8px 16px 16px !important;
  line-height: 2rem !important;
}
.css-1iji0d4 {
  padding: 0px 6px 0px 6px !important;
}
.css-67l5gl {
  position: inherit !important;
}
.css-1fx8m19 {
  padding: 0.8rem !important;
}
.full {
  height: 100%;    
  width: 100%;
}
/* Card Red PopUp */
.css-1wnsr1i {
  width: 94% !important;
  padding: 5px 5px 5px 5px !important;
  border: none !important;
  border-radius: 5px !important;
}
.css-ix1f03-MuiTypography-root {
  font-family: 'Poppins', sans-serif !important;
}
.css-9l5vo-MuiCollapse-wrapperInner {
  box-shadow: 0px 5px 6px #00000012 !important;
}
