.bank-detail-main {
  box-shadow: 0 0 24px -11px #888;
}
.bank-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 6px;
}
.bank-details-sub {
  display: flex;
  column-gap: 10px;
}
.float-right {
  position: absolute;
  left: 88%;
}
/* .bank-details-text {
  font-size: 12px;
  margin-top: 1rem;
} */
/* For claim-detail-inner */
.bank-detail-inner {
  /* display: flex; */
  padding: 0 0 2rem 0;
  column-gap: 12px;
  line-height: 30px;
  margin-left: 10px;
  margin-right: 6px;
  margin-top: 3px;
  max-height: 1000px;
  transition: max-height 0.7s;
  background-color: white;
  /* overflow: scroll; */
  /* border-top: 1px solid lightgrey; */
}

.bank-detail-hide {
  /* display: flex; */
  column-gap: 12px;
  line-height: 30px;
  margin-right: 6px;
  margin-left: 10px;
  margin-top: 3px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.7s;
  background-color: white;
}
.person-detail {
  /* display: flex; */
  user-select: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 4px
}

.bank-call {
  float: left;
  text-align: left;
  font-size: 12px;
  color: #252525;
  opacity: 0.7;
  min-width: 100px;
}
.bank-caller {
  float: right;
  color: black;
  font-size: 12px;
  font-weight: 600;
  min-width: 181px;
}
.noclaimletters{
  padding: 10px 15px;
  border-radius: 8px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
  /* background-color: #888;
  color: white!important; */
}


/* new changes for desktop */
@media only screen and (min-width: 1025px) {
.bankdetailsdesktop{
  /* margin-top: 20px; */
  margin-bottom: 60px!important;
  /* position: absolute;
  left: 1.6rem; */
  /* bottom: 0px; */
  width: 96vw;
  margin-left: auto;
  margin-right: auto;
  /* box-shadow: 0 0 24px -11px #888; */
  box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 10px 30px;
  /* background-color: rgb(248, 245, 245); */
  
}
.bankdetailsdesktopClose{
  /* position: absolute;
  right: 5px;
  top: 3px; */
}
#panel1a-header{
  border: 1px solid rgb(238, 235, 235);
  width: 19.4rem;
  border-radius: 8px;
  cursor: pointer;
}
#panel1a-header1{
  border: 1px solid rgb(238, 235, 235);
  width: 16.4rem;
  border-radius: 8px;
  cursor: pointer;
}
.selected-tab-color{
background-color: rgb(228 33 37 / 10%);;
border-radius: 8px;
}
.bankdetailsdesktopview{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* flex-direction: row; */
  margin-top: 30px;
  margin-bottom: 30px;
}
.bankdetailsdesktopviewClaim{
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  /* flex-direction: row; */
  margin-top: 30px;
  margin-bottom: 30px;
}

.documentVisibleSection:nth-child(3n+1){
  /* background-color: red; */
  margin-right: 2.5rem!important;
  margin-left: 2.5rem!important;
}
.documentVisibleSection:nth-child(3n+2){
  /* margin-left: auto!important; */
  /* background-color: yellow; */
  margin-right: 2.5rem!important;
  margin-left: 2.5rem!important;

}
.documentVisibleSection:nth-child(3n+3){
  /* background-color: green; */
  margin-right: 2.5rem!important;
  margin-left: 2.5rem!important;

}


.person-detail {
  display: flex;
  justify-content: space-evenly!important;
  align-items: center!important;
  flex-wrap: wrap;
 /* width:310px; */
 /* width:21rem; */
 width: 30vw;
 /* border: 1px solid red; */
}

.noclaimletters{
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #888;
  color: white!important;
  font-size: 14px;
   margin-top: 10px;

}
 .bank-call{
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: unset;
} 
.bank-caller{
  margin-top: 10px;
  margin-bottom: 10px;
} 
}
/* new changes for desktop */

