.documentVisibleSection {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin: 12px 0px;
  padding: 13px;
}
.DocumentUploadPart {
  max-height: 300px;
  overflow: scroll;
}
.documentVisibleSection:first-child {
  margin-top: 0px;
}
.documentVisibleSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.documentVisibleSectionLs {
  display: flex;
  align-items: center;
  flex: 2;
}
.documentVisibleSectionRs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 100px;
  flex: 1;
}
.DocumentUploadPartImg {
  width: 35px;
}
.DocumentUploadPartImgDiv {
  width: 40px;
  height: 40px;
  background: #f4f4f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.document_type {
  color: #242424;
  font-size: 14px;
}

.document_date {
  color: #a7a7a7;
  font-size: 12px;
}
.details_copntainer_documents {
  padding-left: 15px;
  line-height: 1.3;
}

.DocumentUploadPartError {
  width: 100%;
  margin: 10px 0px;
  padding: 15px;
  background: rgba(228, 33, 37, 0.05);
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.DocumentUploadPartError > p {
  color: #e42125;
}
.DocumentUploadPartError > img {
  margin-right: 12px;
}
.DocumentUploadPart:first-child {
  margin-top: unset;
}
.upload_section {
  /* border: 1px solid #E42125; */
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 12px 5px;
  width: 100%;
  margin-top: 20px;
  background-color: #67686b;
  color: #ffffff;
  
  /* box-shadow: 0px 1px 10px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
}
.upload_section > select {
  border: none;
  outline: none;
  width: 98%;
  font-size: 16px;
  font-weight: 500;
  /* opacity: 0.92; */
  cursor: pointer;
  background: none;
  padding-left: 6px;
  /* color: white; */
  /* -webkit-appearance:none; */
  -webkit-background:none; -moz-background:none;
  background-color: #6a6a6b;
  color: #ffffff;
  cursor: pointer;
}


.upload_section > select> option {
  background: none;
  /* background-color: #67686b;
  color: #ffffff; */
  /* -webkit-appearance:none; */
  -webkit-background:none; -moz-background:none;

}

/* ================optima secure=========== */

.optima_secure_div {
  background: linear-gradient(180deg, orange 0%, #ffdb99 100%);
    border-radius: 8px;
  color: #ffffff;
  padding: 15px;
}
.optima_secure_div_green{
  background: linear-gradient(180deg, green 0%, rgb(143 217 100) 100%);
  border-radius: 8px;
  color: #ffffff;
  padding: 15px;
}
.optima_secure_div_red{
  background: linear-gradient(180deg, #e42027 0%, #e46721 100%);
  border-radius: 8px;
  color: #ffffff;
  padding: 15px;
}
.optima_secure_div_initiated{
  background: linear-gradient(180deg, #7367F0 0%, #CE9FFC 100%);
  border-radius: 8px;
  color: #ffffff;
  padding: 15px;
}
.optima_secure_imgtext {
  display: flex;
}
.optima_secure_img {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #ffffff;
  border-radius: 20px;
  margin-right: 15px;
}
.optima_secure_txt > p:first-child {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}
.optima_secure_txt > p:last-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.optima_secure_txt_f {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
}
.optima_secure_txt_s {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.doc_ver_dot {
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  margin-right: 2px;
}
.optima_secure_txt_s > p {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
.optima_secure_txt_l {
  text-align: center;
  margin-top: 10px;
}
.optima_secure_txt_l > p:first-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.optima_secure_txt_l > p:last-child {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  margin-top: 3px;
}
#text_color{
  color: #e42027;
}

@media only screen and (min-width: 1025px) {
.documentVisibleSection:first-child {
  margin-top: 12px;
}
.documentVisibleSection{
  padding: 8px 13px;
}
}