.document-status-main {
  /* box-shadow: 0 0 24px -11px #888; */
  /* box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 10%); */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 35px #00000012;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 8px;
}
.document-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 6px;
}
.document-status-sub {
  display: flex;
  column-gap: 10px;
}
.float-right {
  position: absolute;
  left: 94%;
}
.document-status-text {
  font-size: 12px;
  margin-top: 0.4rem;
}
.heading {
  font-size: 14px;
  font-weight: 600;
  padding-right: 10px;
}

.document_status {
  display: flex;
  align-items: center;
}
/* For claim-detail-inner */
.document-status-inner {
  /* display: flex; */
  column-gap: 12px;
  line-height: 30px;
  margin-left: 10px;
  margin-right: 6px;
  margin-top: 3px;
  max-height: 1000px;
  transition: max-height 0.5s;
  background-color: white;
  padding: 0 0 2rem 0;
  /* overflow: scroll; */
  /* border-top: 1px solid lightgrey; */
}
.document-status-hide {
  /* display: flex; */
  column-gap: 12px;
  line-height: 30px;
  margin-left: 10px;
  margin-top: 3px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s;
  transition: all 2.5s cubic-bezier(0, 1, 0, 1);
  background-color: white;
}
.small-div {
  display: flex;
  column-gap: 12px;
  font-size: 12px;
  font-weight: 500;
}
.back-arrow {
  height: 2rem;
}

.documents_required_Section {
  padding: 12px 5px;
}

.pending_status {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}
