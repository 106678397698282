.feedback-border {
  border-radius: 5px;
  background: #ffffff;
  opacity: 1;
  border-radius: 4px;
  padding: 0.5rem;
  height: 10.4rem;
  box-shadow: inset 0 0 4px #00000080;
}
.feedback-borderr {
  background: #ffffff;
  opacity: 1;
  border-radius: 4px;
  padding: 0.5rem;
  height: 8rem;
  box-shadow: inset 0 0 4px #00000080;
}
.feedback-text {
  margin-top: 24px;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 700;
  color: #1c1c1c;
  opacity: 0.84;
}
.feedback-textarea {
  width: 100%;
  background-color: #fefefe;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 13px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.feedback-submit {
  padding: 9px;
  width: 100px;
  margin-left: 6px;
  float: right;
  border: none;
  color: #fff;
  background-color: #e41e26;
  border-color: #e41e26;
  border-radius: 4px;
}
