.card-red-main {
  height: 9rem;
  padding: 8px;
  border-radius: 5px;
  background-image: linear-gradient(0deg, #e41e26, #fd740c);
}
.red-card-content {
  display: flex;
  justify-content: space-around;

  color: #f2f2f2;
  font-size: 11px;
}
.yellow-card-amount {
  font-size: 14px;
  color: #f2f2f2;
  opacity: 1;
  font-weight: 600;
  text-align: center;
}
.red-card-status {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
  align-items: center;
  font-size: 11px;
  color: #f2f2f2;
  padding-bottom: 6px;
}

/* For Font Sizing */
.red-semi-bold {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  color: #f2f2f2;
  opacity: 1;
}
.yellow-less-bold {
  font-size: 12px;
  color: #f2f2f2;
  font-weight: 100;
  opacity: 0.84;
}

.yellow-card-box {
  margin-top: 10px;
}

.card-amount {
  text-align: center;
}
.red-dot {
  height: 10px;
  width: 10px;
  background-color: #ffffff;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}

.red-close {
  background-color: #e41e26;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 0.4rem 2rem;
  margin-top: 2rem;
  width: 160px;
  cursor: pointer;
}
.red-disclosure {
  color: #252525;
  opacity: 0.5;
}
.ReactModal__Overlay {
  position: fixed !important;
  inset: 0px !important;
  background-color: #252525 !important;
  opacity: 0.93 !important;
}
/* .ReactModal__Overlay--after-open {
  position: fixed;
  inset: 0px;
  background-color: #252525;
  opacity: 0.62;
} */

.link1{
  border-bottom: 1px solid white;
  cursor: pointer;
  animation: blinker 1.5s linear infinite;
  position: relative;
}
.link1 .tooltiptext {
  visibility: hidden;
  width: 134px;
  /* width: fit-content; */
  background-color: whitesmoke;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;
  margin-left: -20px;
  /* Position the tooltip */
  position: absolute;
  z-index: 111;
}
.link1:hover .tooltiptext {
  visibility: visible;
}
.link2{
  display: none!important;
}


@keyframes blinker {
  50% {
    opacity: 0;
  }
}