@keyframes scaling {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.main_cx_container {
  animation: scaling 0.3s;
}
.claim_data_loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ball {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: lightgrey;
  animation: bounce 0.3s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
}
.ball::after {
  animation: scaleend 0.5s;
}

.ClaimStatusInformation{
  display: none;
}

@keyframes scaleend {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(60);
  }
}

.scale {
  transform: scale(10);
  transition: 1s;
}

@keyframes bounce {
  from {
    transform: translate3d(0, -100px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
/* Prefix Support */

ball {
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 200px, 0);
  }
}
.claimDetailsForDesktop{
  display: none;
}
/* mainAppContainer */
@media only screen and (min-width: 1025px) {
  
  .mainAppContainer {
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    /* width: 88vw; */
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .mainAppContainerCard {
    width: 350px;
  }
  .mainAppDetails {
    margin-top: 30px;
    margin-bottom: 16px;
    display: flex;
    /* justify-content: space-evenly; */
    /* flex-wrap: wrap; */
    /* box-shadow: 0 0 24px -11px #888; */
  }
  .mainAppDetailsSection {
    margin: 10px auto !important;
    margin-top: 20px !important;
  }
  .ratingPlusTracker {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    
  }
  .ClaimStatusInformation {
    box-shadow: 0 0 24px -11px #888;
    width: 26.6%;
    padding:10px 20px;
    border-radius: 8px;
  }
  .ClaimStatusInformationP {
    font-size: 16px;
    font-weight: 700;
    color: #1c1c1c;
    opacity: 0.84;
    padding-bottom: 8px;
  }
  .ClaimStatusInformationP1 {
    font-size: 12px;
    color: #888;
  }
  .blink_me {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    background: #e42125;
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: 8px;
    animation: blinker 1s linear infinite;
  }
  .ClaimStatusInformation{
    display: block;
  }
  .claimDetailsForMobile{
    display: none;
  }
  .claimDetailsForDesktop{
    display: block;
    margin-top: -40px;
    overflow-y: scroll;
  }
  .documentVisibleSection{
    width: 330px!important;
  }
  .notpreauthorcashless{
    margin-top: 300px!important;
  }
  .claimDetailsForDesktopPauth{
    margin-top: 10px;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}
