@keyframes alertanimation {
    from {
        transform: translateX(200%);
    }
    to {
        transform: translateX(0%);
    }
}

.alert_container {
    height: 45px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    left: 10px;
    z-index: 15;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
    animation: alertanimation 0.5s;
}

.alert_subcontainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    background-color: inherit;
    border-radius: 5px;
}

.alert_subcontainer .alert_child , .alert_progress {
    color: white;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    padding: 4px 10px 4px 15px;
}

@media only screen and (min-width:600px) {
    .alert_container{
        width: 490px;
        margin-left: auto;
        margin-right: auto;
    }
    @keyframes alertanimation {
        from {
            transform: translateX(center);
        }
        to {
            transform: translateX(center);
        }
    }
    }